
import request from "../../Utils/curl"

let GameHm = {
  /**
   * gameHmWordView
   */
  async gameHmWordView (context, gameHmObj = {}) {
    try {
      let postData = new FormData()
      for (let key in gameHmObj) {
        postData.append(key, gameHmObj[key])
      }
      return await request.curl(context, "game_hm_word_view", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at gameHmWordView() and Exception:", err.message)
    }
  },
  /**
   * game_hmList
   */
  async game_hmList (context) {
    try {
      let postData = new FormData()

      return await request.curl(context, "game_hm_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at game_hmList() and Exception:", err.message)
    }
  },

  /**
     * game_hmView
     */
  async game_hmView (context, gameHmId) {
    try {
      let postData = new FormData()
      postData.append("game_hm_id", gameHmId)
      return await request.curl(context, "game_hm_view", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at game_hmView() and Exception:", err.message)
    }
  },

  /**
     * game_hmAdd
     */
  async game_hmAdd (context, gameHmObj) {
    try {
      let postData = new FormData()

      for (let key in gameHmObj) {
        postData.append(key, gameHmObj[key])
      }

      return await request.curl(context, "game_hm_add", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at game_hmAdd() and Exception:", err.message)
    }
  },

  /**
     * game_hmEdit
     */
  async game_hmEdit (context, gameHmObj) {
    try {
      let postData = new FormData()

      for (let key in gameHmObj) {
        postData.append(key, gameHmObj[key])
      }

      return await request.curl(context, "game_hm_edit", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at game_hmEdit() and Exception:", err.message)
    }
  },

  /**
     * game_hmDelete
     */
  async game_hmDelete (context, gameHmId) {
    try {
      let postData = new FormData()

      postData.append("game_hm_id", gameHmId)

      return await request.curl(context, "game_hm_delete", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.log("Exception occurred at game_hmDelete() and Exception:", err.message)
    }
  },
  /**
   * Game Result
   */
  async gameResult (context, gameHmObj) {
    try {
      let postData = new FormData()

      for (let key in gameHmObj) {
        postData.append(key, gameHmObj[key])
      }

      return await request.curl(context, "game_result", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at gameResult() and Exception:", err.message)
    }
  }
}

export {
  GameHm
}
