<template>
  <div>
    <h3 class="text-center" style="text-transform: capitalize;"> {{ gameType }}</h3>
    <div class="hangman">
      <hangman-game
        v-if="isValidData"
        :words="words"
        :show-play-again="true"
        @gameFinished="gameFinished"
      />
    </div>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>

<style lang="scss">
  #hangman {
    .btn-secondary {
      background: var(--iq-secondary);
    }
  }
</style>

<script>
import "vue-games"
import { socialvue } from "../../../../config/pluginInit"
import { GameHm } from "../../../../FackApi/api/gameHm"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { base64decode } from "nodejs-base64"

export default {
  data () {
    return {
      words: null,
      app_id: "",
      gameType: "",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Game"
    }
  },
  mounted () {
    if (this.$route.params.app_id) {
      this.app_id = this.$route.params.app_id
    }
    this.gameHmWordView()
    socialvue.index()
  },
  computed: {
    isValidData () {
      return this.words !== null && this.words !== undefined && this.words.length >= 0
    }
  },
  methods: {
    gameFinished: function (word, lose) {
      console.log("game finished!!!")
      console.log("user was guessing word:", word)
      console.log("she/he/it lost?", lose)
      this.gameHmWordView()
      this.gameResult(word, lose)
    },
    /**
     * gameHmWordView is used to view the word for the game
     */
    async gameHmWordView () {
      try {
        let payload = {
          app_id: this.app_id
        }
        let gameHmWordViewResp = await GameHm.gameHmWordView(this, payload)
        if (gameHmWordViewResp && gameHmWordViewResp.resp_status) {
          let arr = []
          for (let i = 0; i < gameHmWordViewResp.resp_data.data.length; i++) {
            arr.push(base64decode(gameHmWordViewResp.resp_data.data[i].word))
          }
          this.gameType = gameHmWordViewResp.resp_data.data[0].game_type
          this.$set(this, "words", arr)
        }
      }
      catch (err) {
        console.error("Exception occurred at gameHmWordView() and Exception:", err.message)
      }
    },
    /**
     * Result
    */
    async gameResult (word, lose) {
      let payload = {
        word: word,
        lose: lose,
        app_id: this.app_id,
        game_type: this.gameType,
        app_type: "game"
      }
      let gameResultResponse = await GameHm.gameResult(this, payload)
      if (!gameResultResponse.resp_status) {
        ApiResponse.responseMessageDisplay(this, gameResultResponse)
      }
    }
  }
}
</script>
